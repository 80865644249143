<template>
  <div>
    <el-form label-position="left" class="common-form left_10" label-width="auto" style="padding-top: 10px"
             :model="form" :rules="rules" ref="form">
      <el-form-item label="心跳间隔时间：" class="label24" prop="heartbeatInterval">
        <el-input class="small-input" type="number" v-model="form.heartbeatInterval" placeholder="心跳间隔时间"></el-input><span style="margin-left: 5px">秒</span>
      </el-form-item>
      <el-form-item label="休眠后自动唤醒间隔时间：" class="label24" prop="awakenInterval">
        <el-input class="small-input" type="number" v-model="form.awakenInterval" placeholder="休眠后自动唤醒间隔时间"></el-input><span style="margin-left: 5px">秒</span>
      </el-form-item>
      <el-form-item label="休眠倒计时时间：" class="label24" prop="countDown">
        <el-input class="small-input" type="number" v-model="form.countDown" placeholder="休眠倒计时时间"></el-input><span style="margin-left: 5px">秒</span>
      </el-form-item>
      <el-form-item label="RFID识别非法后几秒开锁：" class="label24" prop="rfidUnlock">
        <el-input class="small-input" type="number" v-model="form.rfidUnlock" placeholder="RFID识别非法后几秒开锁"></el-input><span style="margin-left: 5px">秒</span>
      </el-form-item>
      <el-form-item label="开锁重试次数：" class="label24" prop="unlockRetry">
        <el-input class="small-input" type="number" v-model="form.unlockRetry" placeholder="开锁重试次数"></el-input><span style="margin-left: 5px">次</span>
      </el-form-item>
      <el-form-item label="开锁后未取货自动关锁间隔时间：" class="label24" prop="nopickupInterval">
        <el-input class="small-input" type="number" v-model="form.nopickupInterval" placeholder="开锁后未取货自动关锁间隔时间"></el-input><span style="margin-left: 5px">秒</span>
      </el-form-item>
      <el-form-item label="电量不足百分比：" class="label24" prop="electricity">
        <el-input class="small-input" type="number" v-model="form.electricity" placeholder="电量不足百分比"></el-input><span style="margin-left: 5px">%</span>
      </el-form-item>
      <el-form-item label="语音播报">
        <el-table
                :data="tableData"
                class="table voice_class"
                height="240px"
                style="width:700px">
          <el-table-column
                  prop="type"
                  label="类型"
                  width="80">
          </el-table-column>
          <el-table-column
                  prop="voiceName"
                  label="名称"
                  width="200">
          </el-table-column>
          <el-table-column
                  prop="voiceText"
                  label="播报内容"
                  width="300">
          </el-table-column>
          <el-table-column
                  width="120">
            <template slot="header">
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="addVoice(true)"></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="goEdit(true,scope.row)" icon="el-icon-edit"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div class="btn-list">
      <el-button style="padding: 12px 50px" type="primary" :loading="loading" @click="save">保 存</el-button>
    </div>
    <AddVoice :dialogVisible="voiceDialogVisible" :currentData="currentData" @close="addVoice"></AddVoice>
  </div>
</template>

<script>
  import AddVoice from "@/views/site/AddVoice";
export default {
  name: "ChairConfig",
  components: {AddVoice},
  computed: {
    rules() {
      return {
        name: [
          {required: true, message: '请输入单位名称', trigger: 'blur'},
        ]
      }
    },
  },
  data() {
    return {
      form: {
        heartbeatInterval:null,
        awakenInterval:null,
        countDown:null,
        rfidUnlock:null,
        unlockRetry:null,
        nopickupInterval:null,
        electricity: null
      },
      isValidateLocation: false,
      voiceDialogVisible: false,
      loading:false,
      currentData:{},
      tableData: []
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goEdit(visible,data){
      this.currentData = data;
      this.voiceDialogVisible = visible;
    },
    addVoice(visible,isRe){
      this.currentData = {};
      this.voiceDialogVisible = visible;
      if(isRe) this.selectTableData();
    },
    selectTableData(){
      this.$axios.get("/sys-api/device-api/selectVoices",{},(res) => {
        if(res.code === '100'){
          this.tableData = res.data;
        }
      })
    },
    selectVoiceConfig(){
      this.$axios.get("/sys-api/device-api/selectVoiceConfig",{},(res) => {
        if(res.code === '100'){
          this.form = res.data;
        }
      })
    },
    save(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios.post("/sys-api/device-api/saveChairConfig",this.form,(res) => {
            this.loading = false;
            if(res.code === '100'){
              this.$message.success("配置成功");
              this.$router.push({
                name:'SiteList'
              })
            }
          })
        }else  this.isValidateLocation = false;
      })
    }
  },
  mounted() {
    this.selectVoiceConfig();
    this.selectTableData();
  }
}
</script>

<style scoped lang="scss">
.small-input {
  width: 300px;
}

.btn-list {
  margin-left: 100px;
  margin-top: 20px;
}
::v-deep .el-table .el-table__cell{
  padding: 0px;
}
::v-deep input.el-input__inner::-webkit-outer-spin-button,
::v-deep input.el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input.el-input__inner[type="number"] {
  -moz-appearance: textfield !important;
}
  .label24{
    height: 24px;
  }
</style>
