<template>
  <el-dialog
          title="语音播报"
          :visible.sync="dialogVisible"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :before-close="handleClose"
          width="420px">
    <el-form label-position="left" class="common-form left_10" label-width="auto" style="padding-top: 10px"
             :model="form" :rules="rules" ref="form">
      <el-form-item label="type：" prop="type">
        <el-input class="small-input" :disabled="this.isShow" type="number" v-model="form.type" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="名称：" prop="voiceName">
        <el-input class="small-input" v-model="form.voiceName" placeholder="名称"></el-input>
      </el-form-item>
      <el-form-item label="播报内容：" prop="voiceText">
        <el-input class="small-input" v-model="form.voiceText" placeholder="播报内容"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="closeModal">取 消</el-button>
    <el-button size="small" type="primary" :loading="loading" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "AddVoice",
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    currentData: {
      type: Object,
      default() {
        return null;
      }
    },
  },
  watch:{
    dialogVisible(val){
      if(!val){
        this.form = {
          id:null,
          type:null,
          voiceName:null,
          voiceText:null
        }
        this.$refs.form.resetFields();
      }else {
        if (typeof (this.currentData.id) != 'undefined') {
          this.isShow = true;
          this.form = this.currentData;
        }else {
          this.isShow = false;
        }
      }
    }
  },
  computed: {
    rules() {
      return {
        type: [
          {required: true, message: '请输入type', trigger: 'blur'},
        ]
      }
    },
  },
  data() {
    return {
      isShow:false,
      form: {
        id:null,
        type:null,
        voiceName:null,
        voiceText:null
      },
      isValidateLocation: false,
      loading:false
    }
  },
  methods: {
    handleClose() {
      this.closeModal()
    },
    closeModal(isRe){
      this.$emit("close", false,isRe);
    },
    save(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios.post("/sys-api/device-api/addVoice",this.form,(res) => {
            this.loading = false;
            if(res.code === '100'){
              this.$message.success("添加成功");
              this.closeModal(true);
            }
          })
        }else  this.isValidateLocation = false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.small-input {
  width: 300px;
}

.btn-list {
  margin-left: 100px;
  margin-top: 20px;
}

::v-deep input.el-input__inner::-webkit-outer-spin-button,
::v-deep input.el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input.el-input__inner[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
